import { IToastType,  IToastSize, IToastContrast  } from '../../typings';

type IFileDescription = 'model' | 'video' | 'image' | 'audio' | 'file';

const errorSettings = {
    type: IToastType.error,
    contrast: IToastContrast.high, 
    size: IToastSize.mini, 
    closable: true
}

const successSettings = {
    type: IToastType.success, 
    contrast: IToastContrast.default, 
    size: IToastSize.mini, 
    closable: true
}

const infoSettings = {
    type: IToastType.info, 
    contrast: IToastContrast.default, 
    size: IToastSize.mini,
    closable: true
}

export const ToastsData = {
    GeneralError: {
        ...errorSettings,
        message: "Something went wrong. Try reloading the page.", 
    }, 
    PreviewError: {
        ...errorSettings,
        message: "Unable to preview project. Try again later.", 
    }, 
    PreviewNoTrkImgError: {
        ...errorSettings,
        message: "Add a target image to be able to preview.", 
    },
    PreviewNo360ContentError: {
        ...errorSettings,
        message: "At least one scene is missing a 360 background. Please add to be able to preview.",
    },
    PreviewNoTrkImgAnd360ContentError: {
        ...errorSettings,
        message: "A target image and one or more 360 background(s) is missing. Please add to be able to preview.",
    },
    PublishSuccess: {
        ...successSettings,
        message: "Project successfully published!", 
    },
    PublishError: {
        ...errorSettings,
        message: "Unable to publish project. Try again later.", 
    }, 
    PublishNo360ContentError: {
        ...errorSettings,
        message: "At least one scene is missing a 360 background. Please add to be able to publish."
    },
    PublishNoTrkImgAnd360ContentError: {
        ...errorSettings,
        message: "A target image and one or more 360 background(s) is missing. Please add to be able to publish.",
    },
    PublishNoTrkImgError: {
        ...errorSettings,
        message: "Add a target image to be able to publish.", 
    },
    NoAccessGrantedError: {
        ...errorSettings,
        message: "You don't have access to this project. Choose another project from ZapWorks.",
        closable: false,
    },
    SessionExpiredError: {
        ...errorSettings,
        message: "Your session has expired. Refresh the page to log back in to ZapWorks.",
        closable: false,
    },
    MismatchedVersionError: {
        ...errorSettings,
        message:'Designer has been updated. Please reload the page for the latest version.',
        closable: false,
    },
    SocketConnectionError: {
        ...errorSettings,
        message:'Unable to connect to server. Try reloading the page.',
        closable: false,
    }, 
    SocketConnectionSuccess: {
        ...successSettings,
        message: 'Connection with server established.',
    },
    SocketReconnecting: {
        ...infoSettings, 
        message: 'Connecting to server.',
    },
    CopyToClipboard: {
        ...infoSettings, 
        message: 'Copied to clipboard.',
    },
    AuthorizationError: {
        ...errorSettings,
        message: 'Authentication failed. Try reloading the app.',
        closable: false,
    },
    InternalServerError: {
        ...errorSettings,
        message: 'Internal server error. Try reloading the app.', 
        closable: false,
    }, 
    FailureLoadingError: {
        ...errorSettings,
        message: 'Failure loading component.',
    },
    MaxUploadSizeExceededError: (filesize: number, fileDesc: IFileDescription) => {
        return {
            ...errorSettings,
            message: `Maximum upload size for ${fileDesc} files exceeded (${filesize}MB) .`,
        }
    },
    ErrorWithCustomMessage: (message: string) => ({
        ...errorSettings,
        message,
    })

}